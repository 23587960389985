import React, { FunctionComponent } from "react";
import { OFFICES } from "../../constants";
import { useI18next } from "../../utils/i18n";
import { clip } from "./index.module.css";

export type Props = {};

const Offices: FunctionComponent<Props> = () => {
  const { t } = useI18next();

  return (
    <>
      <div className={clip} />

      <div className="bg-mine-shaft">
        <div className="constrained pt-64 lg:pt-20 pb-16 lg:flex text-silver">
          <div className="lg:w-1/4 lg:pr-[50px]">
            <div className="cubicle bg-neon-green" />
            <h2 className="h1 mt-12 mb-6 lg:mb-12 text-white font-bold">
              {t("offices.title")}
            </h2>
            <p className="mb-16 text-lg">{t("offices.paragraph")}</p>
          </div>

          <div className="lg:w-[calc(75%+50px)] sm:flex flex-wrap sm:-ml-[50px] lg:pt-48">
            {OFFICES.map((office) => (
              <div
                key={office.location}
                className="sm:w-[calc(33.33%-50px)] sm:ml-[50px] pt-12 pb-16 sm:pb-20 border-t-2 border-neon-green"
              >
                <h3 className="mb-6 font-bold text-2xl text-white">
                  {office.location}
                </h3>
                <address className="not-italic text-sm">
                  <p>{office.addressLine1}</p>
                  <p className="mb-[15px]">{office.addressLine2}</p>
                  <a
                    href={`mailto:${office.email}`}
                    className="link text-white font-medium"
                  >
                    {office.email}
                  </a>
                </address>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Offices;
