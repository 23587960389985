import React from "react";
import { PageProps, graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Offices from "../components/Offices";

export type Props = {};
type DataProps = {
  markdownRemark: {
    frontmatter: {
      title?: string;
      description?: string;
    };
    html: any;
  };
};

const MarkdownPageTemplate: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;

  return (
    <Layout>
      <SEO
        title={frontmatter.title || "Enea Consulting"}
        description={frontmatter.description || ""}
      />
      <div className="constrained">
        <div className="prose lg:prose-xl my-[65px] md:my-[85px] max-w-[836px] mx-auto">
          <h1>{frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>
      <Offices />
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String, $locale: String!) {
    markdownRemark(fields: { slug: { eq: $slug }, lang: { eq: $locale } }) {
      html
      frontmatter {
        description
        title
      }
    }
  }
`;

export default MarkdownPageTemplate;
