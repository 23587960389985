import React from "react";
import { Helmet } from "react-helmet";

type Props = {
  title: string;
  description: string;
  image?: string;
  meta?: Array<{
    name: string;
    content: string;
  }>;
};

const SEO: React.FC<Props> = ({ description, meta = [], image, title }) => {
  return (
    <Helmet
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: image ? `summary_large_image` : `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ]
        .concat(
          image
            ? [
                { property: `og:image`, content: image },
                { property: `twitter:image`, content: image },
              ]
            : [],
        )
        .concat(meta)}
    />
  );
};

export default SEO;
